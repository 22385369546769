import React, { useState } from "react";
import NavigationBar from "./NavigationBar";
import { useNavigate } from "react-router-dom";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/signup`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage("Signup successful!");
                navigate("/login");
            } else {
                setMessage(data.message || "Failed to signup");
            }
        } catch (error) {
            console.error("Signup error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center justify-center h-[92vh] mx-4">
                <div className="
                    w-full md:w-96 p-4 border border-gray-300 rounded-md
                ">
                    <h2 className="text-2xl md:text-3xl font-bold text-center">Sign Up</h2>
                    <p className="text-base text-center text-gray-600 mb-4">
                        Please enter your email and password to signup.
                    </p>
                    <form onSubmit={handleSignup}>
                        <label className="text-gray-900 font-semibold">
                            Email:
                            <input
                                className="w-full p-2 border border-gray-300 rounded-md mb-2 font-medium text-gray-700"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </label>
                        <br />
                        <label className="
                            text-gray-900 font-semibold
                        ">
                            Password:
                            <input
                                className="
                                    w-full p-2 border border-gray-300 rounded-md
                                    mb-2 font-medium text-gray-700
                                "
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </label>
                        <br />
                        <button className="
                            w-full p-2 bg-blue-500 text-white rounded-md
                            hover:bg-blue-600
                        " type="submit">Sign Up</button>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </div>
        </div>
    );
};

export default Signup;

