import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";

const History = () => {
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const email = sessionStorage.getItem("userEmail"); // Assume the logged-in user's email is saved in localStorage

    useEffect(() => {
        if (email) {
            // Fetch history from localStorage for the logged-in user
            const storedHistory = JSON.parse(localStorage.getItem(email)) || [];
            setHistory(storedHistory);
        }
    }, [email]);

    if (!email) {
        return (
            <div>
                <NavigationBar />
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <h1 className="text-2xl font-semibold">Login Required</h1>
                    <p className="mt-4 text-gray-600">You need to log in to view your history.</p>
                    <button
                        onClick={() => navigate("/login")}
                        className="bg-blue-500 text-white py-2 px-4 rounded mt-4 hover:bg-blue-600"
                    >
                        Go to Login
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center justify-center min-h-screen">
                <h1 className="text-2xl font-semibold mb-4">Print Job History</h1>
                {history.length === 0 ? (
                    <p className="text-gray-600">No history available.</p>
                ) : (
                    <div className="w-full md:w-96">
                        {history.map((job, index) => (
                            <div
                                key={index}
                                className="p-4 mb-4 border rounded-md bg-white shadow-sm"
                            >
                                <p className="font-bold">Printer Shop: {job.printerShop}</p>
                                <p>Total Cost: {job.totalCost} ₹</p>
                                <p>Files: {job.files.join(", ")}</p>
                                <p>Date: {job.date}</p>
                                <p>Print Options: {JSON.stringify(job.printOptions)}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default History;
