import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import NavigationBar from "./NavigationBar";
import { SlSettings } from "react-icons/sl";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { PiRulerThin } from "react-icons/pi";
import { RiFilePaperLine } from "react-icons/ri";
import { IoIosColorFill, IoIosCheckmarkCircle, IoIosArrowForward } from "react-icons/io";
import { RxMargin } from "react-icons/rx";
import { TbFileOrientation } from "react-icons/tb";
import Footer from "./Footer";


const ReviewAndPrint = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure safely with defaults
    const { files = [], options = {}, email = "" } = location.state || {};

    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [fileOptions, setFileOptions] = useState(
        files.map(() => ({
            paperSize: options.paperSize || "A4",
            printType: options.printType || "Glossy",
            colorOption: options.colorOption || "Colored",
            pagesPerSheet: options.pagesPerSheet || "1",
            margins: options.margins || "Normal",
            layout: options.layout || "Portrait",
        }))
    );
    const [applyToAll, setApplyToAll] = useState(false);
    const [saving, setSaving] = useState(false);

    // Ensure required data is present
    useEffect(() => {
        if (!files.length) {
            alert("No files to review.");
            navigate("/");
        }
    }, [files, navigate]);

    const reviewComplete = () => {
        return applyToAll || (currentFileIndex === files.length - 1);
    };

    const handleOptionChange = (optionType, value) => {
        setFileOptions((prevOptions) =>
            prevOptions.map((fileOption, index) =>
                index === currentFileIndex
                    ? { ...fileOption, [optionType]: value }
                    : fileOption
            )
        );
    };

    const handleNext = () => {
        if (currentFileIndex < files.length - 1) {
            setCurrentFileIndex(currentFileIndex + 1);
        }
    }

    const handleApplyToAll = () => {
        const updatedOptions = fileOptions[currentFileIndex];
        setFileOptions(files.map(() => updatedOptions));
        setApplyToAll(true);
        alert("Options applied to all files.");
    };

    const handleSaveToDatabase = async () => {
        setSaving(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}/reviewfiles`, {
                files: files,
                fileOptions,  // Send the options
                email,  // Send the email or guest ID
            });

            alert("Files saved successfully!");
            navigate("/select-printer-shop", { state: { savedFiles: response.data.savedFiles, files, fileOptions, email } });
        } catch (error) {
            console.error("Error saving files:", error);
            alert("Failed to save files. Please try again.");
        } finally {
            setSaving(false);
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="
                flex flex-row flex-wrap justify-between items-center
                w-fit md:w-full mx-4 md:max-w-[90%] md:mx-auto
                mt-8 px-4 py-4 bg-[#E1E1E1]
                rounded-lg shadow-md border-2 border-black
            ">
                <h1 className="
                    text-xl
                    md:text-3xl font-semibold text-left
                    flex items-center
                ">
                    Review your Files & Choose Print Options
                </h1>
                <p className="
                    text-sm
                    md:text-base text-left font-medium
                    text-slate-600
                ">
                    Viewing as: <span className="underline font-semibold">{email}</span>
                </p>
            </div>
            <div className="
                flex justify-between
                w-fit md:w-full md:max-w-[90%] md:mx-auto
            ">
                <div className="
                    my-4 w-1/2 mr-4 bg-[#E1E1E1] p-4
                    rounded-lg shadow-md border-2 border-black
                ">
                        <h1 className="
                            text-xl font-semibold text-left
                            flex items-center
                        ">
                            <SlSettings className="mr-2" />
                            Document Print Settings:
                        </h1>
                        <p className="text-base font-medium text-left text-zinc-500">
                            Customize the print settings for each document.
                        </p>
                    <div>
                        <div>
                            <h2 className="text-lg font-semibold mt-4 flex items-center gap-x-2">
                                <PiRulerThin className="text-2xl"/>
                                Select Paper Dimensions:
                            </h2>
                            <div className="flex space-x-4 mt-2">
                                <button
                                onClick={() => handleOptionChange("paperSize", "A4")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].paperSize === "A4"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    A4
                                </button>
                                <button
                                onClick={() => handleOptionChange("paperSize", "A3")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].paperSize === "A3"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    A3
                                </button>
                            </div>
                            <h2 className="text-lg font-semibold mt-4 flex items-center gap-x-2">
                                <RiFilePaperLine className="text-2xl"/>
                                Select Paper Type:
                            </h2>
                            {/* glossy & normal */}
                            <div className="flex space-x-4 mt-2">
                                <button
                                onClick={() => handleOptionChange("printType", "Glossy")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].printType === "Glossy"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Glossy
                                </button>
                                <button
                                onClick={() => handleOptionChange("printType", "Normal")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].printType === "Normal"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Normal
                                </button>
                            </div>
                            <h2 className="text-lg font-semibold mt-4 flex items-center gap-x-2">
                                <IoIosColorFill className="text-2xl"/>
                                Select Color Options:
                            </h2>
                            <div className="flex space-x-4 mt-2">
                                <button
                                onClick={() => handleOptionChange("colorOption", "Colored")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].colorOption === "Colored"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Colored
                                </button>
                                <button
                                onClick={() => handleOptionChange("colorOption", "Black and White")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].colorOption === "Black and White"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Black & White
                                </button>
                            </div>
                            <h2 className="text-lg font-semibold mt-4 flex items-center gap-x-2">
                                <RxMargin className="text-2xl"/>
                                Margins:
                            </h2>
                            <div className="flex space-x-4 mt-2">
                                <button
                                onClick={() => handleOptionChange("margins", "Normal")}
                                className={`p-2 w-1/3 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].margins === "Normal"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Normal
                                </button>
                                <button
                                onClick={() => handleOptionChange("margins", "Narrow")}
                                className={`p-2 w-1/3 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].margins === "Narrow"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Narrow
                                </button>
                                <button
                                onClick={() => handleOptionChange("margins", "Wide")}
                                className={`p-2 w-1/3 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].margins === "Wide"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Wide
                                </button>
                            </div>
                            <h2 className="text-lg font-semibold mt-4 flex items-center gap-x-2">
                                <TbFileOrientation className="text-2xl"/>
                                Orientation:
                            </h2>
                            <div className="flex space-x-4 mt-2">
                                <button
                                onClick={() => handleOptionChange("layout", "Portrait")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].layout === "Portrait"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Potrait
                                </button>
                                <button
                                onClick={() => handleOptionChange("layout", "Landscape")}
                                className={`p-2 w-1/2 font-semibold rounded-md flex flex-row gap-x-2 items-center justify-center ${
                                    fileOptions[currentFileIndex].layout === "Landscape"
                                    ? "bg-[#5360FD] text-white"
                                    : "bg-slate-50 text-slate-800"
                                }`}
                                >
                                    Landscape
                                </button>
                            </div>
                            <div className="my-4">
                                <label className="flex items-center cursor-pointer">
                                    <input
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                    onChange={handleApplyToAll}
                                    />
                                    <span className="ml-2 text-blue-600 hover:text-blue-700">
                                    Apply this to all files
                                    </span>
                                </label>
                            </div>
                            <div>
                                {reviewComplete() && (
                                    <button
                                        onClick={handleSaveToDatabase}
                                        className="
                                    flex items-center gap-x-2 text-xl justify-center w-full p-2 bg-slate-50 rounded-md
                                    hover:cursor-pointer hover:bg-slate-100 transition-all duration-200 ease-linear
                                ">
                                        <IoIosCheckmarkCircle className="text-2xl" />
                                        "Save Changes and Proceed"
                                    </button>
                                )}
                                {!reviewComplete() && (

                                    <button
                                        onClick={handleNext}
                                        className="
                                    flex items-center gap-x-2 text-xl justify-center w-full p-2 bg-slate-50 rounded-md
                                    hover:cursor-pointer hover:bg-slate-100 transition-all duration-200 ease-linear
                                ">
                                        <IoIosArrowForward className="text-2xl" />
                                        "Review next"
                                    </button>
                                )

                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="
                    my-4 w-1/2 ml-4 bg-[#E1E1E1] p-4
                    rounded-lg shadow-md border-2 border-black
                ">
                    <h1 className="
                        text-xl font-semibold text-left
                        flex items-center
                    ">
                        <HiOutlineMagnifyingGlass className="mr-2" />
                        Document Preview
                    </h1>
                    <div>
                        <ul className="
                            text-base font-medium text-left
                            text-slate-600
                            flex flex-row justify-between
                        ">
                            <li>
                                File Name: <span className="font-semibold">{files[currentFileIndex].name || "Unnamed File"}</span>
                            </li>
                            <li>
                                File Size: <span className="font-semibold">{files[currentFileIndex].size} bytes</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="my-4">
                                {files[currentFileIndex] ? (
                                    <img
                                        src={files[currentFileIndex].fileUrl}
                                        alt="Preview"
                                        className={`w-fit flex justify-center items-center border border-gray-300 rounded object-contain ${fileOptions[currentFileIndex].colorOption === "Black and White"
                                            ? "filter grayscale"
                                            : ""
                                            }`}
                                    />
                                ) : (
                                    <p className="text-gray-500">Preview not available</p>
                                )}
                            </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default ReviewAndPrint;
