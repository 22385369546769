import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Success = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Destructure data safely
    const { printerShop, files = [] } = location.state || {};

    if (!printerShop) {
        // Redirect if printerShop is not passed
        navigate("/");
        return null;
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-3xl font-semibold text-green-600">Success!</h1>
            <p>Your job has been sent to:</p>
            <p className="font-bold">{printerShop.name}</p>
            <p>File Count: {files.length || 0}</p>
        </div>
    );
};

export default Success;
