import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate(); // Initialize useNavigate

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage("Login successful!");
                sessionStorage.setItem("token", data.token); // Store JWT token
                sessionStorage.setItem("userEmail", email); // Store user email
                navigate("/"); // Redirect to Home page after login
            } else {
                setMessage(data.message || "Failed to login");
            }
        } catch (error) {
            console.error("Login error:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    return (
        <div>
            <NavigationBar />
            <div className="flex flex-col items-center justify-center h-[92vh] mx-4">
                <div className="w-full md:w-96 p-4 border border-gray-300 rounded-md">
                    <h2 className="text-2xl md:text-3xl font-bold text-center">Login to Printer Service</h2>
                    <p className="text-base text-center text-gray-600 mb-4">
                        Please enter your email and password to login.
                    </p>
                    <form onSubmit={handleLogin}>
                        <label className="text-gray-900 font-semibold">
                            Email Address:
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="w-full p-2 border border-gray-300 rounded-md mb-2 font-medium text-gray-700"
                            />
                        </label>
                        <br />
                        <label className="text-gray-900 font-semibold">
                            Password:
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="w-full p-2 border border-gray-300 rounded-md mb-2 font-medium text-gray-700"
                            />
                        </label>
                        <br />
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 transition-colors duration-200 ease-in-out mt-2 font-medium"
                            type="submit"
                        >
                            Login
                        </button>
                    </form>
                    {message && <p className="mt-4 text-center text-red-600">{message}</p>}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Login;


