import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import SignUp from './components/SignUp';
import ReviewAndPrint from './components/ReviewAndPrint';
import SelectPrinterShop from './components/SelectPrinterShop';
import Success from './components/Success';
import History from './components/History';
function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/review-print" element={<ReviewAndPrint />} />
                <Route path="/select-printer-shop" element={<SelectPrinterShop />} />
                <Route path="/success" element={<Success />} />
                <Route path="/history" element={<History />} />
            </Routes>
        </Router>
    );
}

export default App;


